module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156207160-1","head":true,"siteSpeedSampleRate":10,"cookieDomain":"ombrea.fr","anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#137b35","theme_color":"#137b35","display":"minimal-ui","icon":"src/images/ombrea.inline.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0e1a057ec4c6bfc673b40737615c9f07"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
